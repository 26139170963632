<template>
  <BaseModal
    name="links-new"
    size="lg"
    :title="$t('seller.seller.links.new.text_281')"
    @shown="openModal"
    @hidden="resetItens"
  >
    <b-row  >
      <b-col cols="12">
        <h5 class="produto-nome" v-if="product_name">• {{ product_name }}</h5>
        <div
          class="checks"
          v-if="
            (!loading && !$store.getters.setPanel) ||
            ($store.getters.setPanel && !link_read)
          "
        >
          <b-form-checkbox
            v-model="order_b"
            name="check-button"
            switch
            @change="changeCheckBox($event, 'orderbump')"
          >
            <p class="title-checkbox">Order Bump</p>
          </b-form-checkbox>
          <b-form-checkbox
            v-if="!isPhysical"
            v-model="checkout"
            name="check-button"
            switch
            @change="changeCheckBox($event, 'checkout')"
          >
            <p class="title-checkbox">
              {{ $t("seller.seller.links.new.text_2723") }}
            </p>
          </b-form-checkbox>
          <b-form-checkbox
            v-model="cupom"
            name="check-button"
            @change="changeCheckBox($event, 'cupom')"
            switch
          >
            <p class="title-checkbox">
              {{ $t("seller.seller.links.new.text_2724") }}
            </p>
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div style="background-color: #FFF3E6; " class="d-flex justify-content-between p-3 align-items-center rounded mb-4" v-if="order_b">
          <div class="mr-2" style="width: 40px">
            <img src="@/assets/img/u_exclamation-triangle.svg" />
          </div>
          <div style="font-size: 14px; font-weight: 400; color: #B35B00;">
            Para criar um order bump, a oferta do produto principal e a oferta do order bump precisam ter ao menos um meio de pagamento em comum.
          </div>
        </div>
        <b-form novalidate v-if="!loading">
          <b-form-group
            :label="$t('seller.seller.links.new.text_282')"
            label-for="name"
            v-if="
              order_b ||
              checkout ||
              (cupom && !$store.getters.setPanel) ||
                ($store.getters.setPanel && !link_read) || all_edit
            "
          >
            <b-form-input
              id="name"
              name="name"
              v-model="name"
              type="text"
              placeholder="Meu Link"
              v-validate="'required'"
              :state="errors.has('name') ? !errors.has('name') : null"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              {{ $t("seller.seller.links.new.required") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div v-if="all_edit || (!all_edit && (order_b || cupom || checkout))">
            <b-form-group
              :label="$t('seller.seller.links.new.text_284')"
              label-for="product-main"
            >
              <multiselect
                v-if="type"
                data-anima="top"
                v-model="product_main"
                :placeholder="$t('seller.seller.links.new.text_286')"
                label="name"
                @select="getOffers()"
                :name="`p-link-${productsAffiliateOptions.id}`"
                track-by="id"
                :options="filterBy(productsAffiliateOptions)"
                :multiple="false"
                :taggable="false"
                v-validate="'required'"
                :state="errors.has(`p-link-${productsAffiliateOptions.id}`) ? !errors.has(`p-link-${productsAffiliateOptions.id}`) : null"
              >
                <span slot="noResult">{{
                  $t("seller.seller.coupon.links.new.text_2729")
                }}</span>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                      <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Produto indisponível - em análise)</span>
                  </div>
                </template>
              </multiselect>

              <multiselect
                v-else
                data-anima="top"
                v-model="product_main"
                :placeholder="$t('seller.seller.links.new.text_286')"
                label="name"
                :name="`p-link-${productsSellerOptions.id}`"
                track-by="id"
                :options="filterBy(productsSellerOptions)"
                @select="getOffers()"
                :multiple="false"
                :taggable="false"
                :allow-empty="false"
                v-validate="'required'"
                :class="errors.has(`p-link-${productsSellerOptions.id}`) ? 'is-invalid' : ''"
                :state="errors.has(`p-link-${productsSellerOptions.id}`) ? !errors.has(`p-link-${productsSellerOptions.id}`) : null"
              >
                <span slot="noResult">{{
                  $t("seller.seller.coupon.links.new.text_2729")
                }}</span>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                      <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Produto indisponível - em análise)</span>
                  </div>
                </template>
              </multiselect>
                <b-form-invalid-feedback :state="!errors.has(`p-link-${productsAffiliateOptions.id}`) && !errors.has(`p-link-${productsSellerOptions.id}`)">
                {{ $t("seller.seller.links.new.required") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div v-if="offers">
              <b-form-group :label="$t('seller.seller.links.new.text_2726')">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  :placeholder="$t('seller.seller.links.new.text_2726')"
                  label="name"
                  track-by="id"
                  name="offer"
                  :options="filterByOffer(offers)"
                  :multiple="false"
                  :taggable="false"
                  v-validate="'required'"
                  :class="errors.has(`offer`) ? 'is-invalid' : ''"
                >
                  <span slot="noOptions">{{
                    $t("seller.seller.links.new.text_2732")
                  }}</span>
                  <span slot="noResult">{{
                    $t("seller.seller.links.new.text_2732")
                  }}</span>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                        <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Oferta indisponível - em análise)</span>
                    </div>
                  </template>
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has(`offer`)">
                  {{ $t("seller.seller.links.new.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
              
            </div>
          </div>
          <div v-if="order_b">
            <h5 class="label-title" data-anima="top">Order bump</h5>
            <b-form-group label="Produto Order Bump">
              <multiselect
                v-if="type"
                data-anima="top"
                v-model="product_order_b"
                :placeholder="$t('seller.seller.links.new.text_286')"
                label="name"
                @select="(bOffer) => getOffers(bOffer)"
                :name="`b-link-${productsAffiliateOptions.id}`"
                track-by="id"
                :options="filterBy(productsAffiliateOptions)"
                :multiple="false"
                :taggable="false"
                v-validate="'required'"
                :disabled="!productId"
                :class="errors.has(`b-link-${productsAffiliateOptions.id}`) ? 'is-invalid' : ''"
              >
                <span slot="noResult">{{
                  $t("seller.seller.coupon.links.new.text_2729")
                }}</span>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                      <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Produto indisponível - em análise)</span>
                  </div>
                </template>
              </multiselect>
              <multiselect
                v-else
                data-anima="top"
                v-model="product_order_b"
                :placeholder="$t('seller.seller.links.new.text_286')"
                label="name"
                :name="`b-link-${productsSellerOptions.id}`"
                track-by="id"
                :options="filterBy(productsSellerOptions)"
                @select="(bOffer) => getOffers(bOffer)"
                :multiple="false"
                :taggable="false"
                :disabled="!productId"
                v-validate="'required'"
                :class="errors.has(`b-link-${productsSellerOptions.id}`) ? 'is-invalid' : ''"
              >
                <span slot="noResult">{{
                  $t("seller.seller.coupon.links.new.text_2729")
                }}</span>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                      <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Produto indisponível - em análise)</span>
                  </div>
                </template>
              </multiselect>
                <b-form-invalid-feedback :state="!errors.has(`b-link-${productsAffiliateOptions.id}`) || !errors.has(`b-link-${productsSellerOptions.id}`)">
                {{ $t("seller.seller.links.new.required") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div v-if="JSON.stringify(product_order_b) !== '[]'">
              <!-- <h5 class="label-title" data-anima="top">Oferta Bump (Opcional)</h5> -->
              <b-form-group :label="$t('seller.seller.links.new.text_2730')">
                <multiselect
                  data-anima="top"
                  v-model="offer_bump_select"
                  :placeholder="$t('seller.seller.links.new.text_2731')"
                  label="name"
                  name="offer_bump"
                  track-by="id"
                  :options="filterByOffer(offers_bump)"
                  :multiple="false"
                  :taggable="false"
                  :disabled="!product_order_b"
                  v-validate="'required'"
                  :class="errors.has(`offer_bump`) ? 'is-invalid' : ''"
                >
                  <span slot="noOptions">{{
                    $t("seller.seller.links.new.text_2732")
                  }}</span>
                  <span slot="noResult">{{
                    $t("seller.seller.links.new.text_2732")
                  }}</span>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                      <span class="option__desc" v-if="props.option.status !== 'APPROVED'"> (Oferta indisponível - em análise)</span>
                    </div>
                  </template>
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has(`offer-bump`)">
                  {{ $t("seller.seller.links.new.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
               
            </div>
          </div>
          <div v-if="checkout && !$store.getters.setPanel">
            <h5 class="label-title" data-anima="top">Checkout Personalizado</h5>
            <b-form-group :label="$t('seller.seller.links.new.text_287')">
              <multiselect
                data-anima="top"
                v-model="checkout_select"
                :placeholder="$t('seller.seller.links.new.text_2735')"
                label="name"
                :name="`link-${checkouts.id}`"
                track-by="id"
                :options="checkouts"
                :multiple="false"
                :custom-label="customLabel"
                :taggable="false"
                v-validate="'required'"
                :class="errors.has(`link-${checkouts.id}`) ? 'is-invalid' : ''"
              >
                <div slot="noOptions">
                  <p>
                    {{
                      $t("seller.seller.links.new.noCheckoutOpts")
                    }}
                  </p>
                  <p>
                      <a href="https://faq.voompcreators.com.br/links/como-criar-um-checkout-personalizado/" target="_blank">
                      {{
                        $t("seller.seller.links.new.visitCenterHelpCheckout")
                      }}
                    </a>
                  </p>
                </div>
                <span slot="noResult">
                    {{
                      $t("seller.seller.links.new.text_2732")
                    }}
                </span>
              </multiselect>
                <b-form-invalid-feedback :state="!errors.has(`link-${checkouts.id}`)">
                {{ $t("seller.seller.links.new.required") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div v-if="cupom">
            
            <h5 class="label-title" data-anima="top">
              {{ $t("seller.seller.links.new.text_2736") }}
            </h5>
            <b-form-group :label="$t('seller.seller.links.new.text_288')">
              <multiselect
                data-anima="top"
                v-model="cupom_select"
                :placeholder="$t('seller.seller.links.new.text_288')"
                label="name"
                track-by="id"
                name="coupon"
                v-validate="'required'"
                :options="cupons"
                :multiple="false"
                :taggable="false"
                :class="errors.has(`coupon`) ? 'is-invalid' : ''"
              >
                <span slot="noOptions">{{
                  $t("seller.seller.links.new.text_2737")
                }}</span>
                <span slot="noResult">{{
                  $t("seller.seller.links.new.text_2737")
                }}</span></multiselect>
              <b-form-invalid-feedback :state="!errors.has(`coupon`)">
                {{ $t("seller.seller.links.new.required") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <!-- Generated Link Create -->
          <div v-if="all_edit || (!all_edit && (order_b || cupom || checkout))">
            <div data-anima="top" v-if="productId">
              <b-form-group :label="$t('seller.seller.links.new.text_2738')">
                <b-form-input
                  ref="linkconfig"
                  name="url"
                  v-validate="'required'"
                  :value="
                    formatId(
                      product_order_b,
                      checkout_select,
                      cupom_select,
                      offer_select,
                      offer_bump_select
                    )
                  "
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div v-if="order_b">
            <p style="font-size: 14px">Visite nossa Central de Ajuda e veja: <a style="color: #FF8200" href="https://faq.voompcreators.com.br/links/como-cadastrar-o-orderbump-em-meu-link/" target="__blank">Como Cadastrar o OrderBump em meu Link?</a></p>
          </div>
          <div v-if="!order_b && !checkout && !cupom && !all_edit">
            <div>
              <!-- <b-row>
                <b-col cols="12" md="6"> -->
              <b-form-group
                :label="$t('seller.seller.links.new.text_289')"
                label-for="url"
              >
                <b-form-input
                  id="url"
                  name="url"
                  v-model="url"
                  type="text"
                  placeholder="https://www.voomp.com.br/"
                  v-validate="'required'"
                  data-form="modificado"
                  :state="errors.has('url') ? !errors.has('url') : null"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('url')">
                  {{ $t("seller.seller.links.new.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- </b-col> -->

              <!-- <b-col cols="12" md="6"> -->
              <b-form-group
                :label="$t('seller.seller.links.new.text_299')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="name"
                  type="text"
                  placeholder="Meu Link"
                  v-validate="'required'"
                  :state="errors.has('name') ? !errors.has('name') : null"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t("seller.seller.links.new.required") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- </b-col>
              </b-row> -->

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    :label="$t('seller.seller.links.new.text_291')"
                    label-for="source"
                  >
                    <b-form-input
                      id="source"
                      name="source"
                      v-model="source"
                      type="text"
                      placeholder="utm_source"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('source')">
                      {{ $t("seller.seller.links.new.required") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group
                    :label="$t('seller.seller.links.new.text_293')"
                    label-for="medium"
                  >
                    <b-form-input
                      id="medium"
                      name="medium"
                      v-model="medium"
                      type="text"
                      placeholder="facebook, email"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('medium')">
                      {{ $t("seller.seller.links.new.required") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <!-- novo link gerado-->
                  <b-form-group
                    :label="$t('seller.seller.links.new.text_2738')"
                  >
                    <b-form-input
                      ref="linkconfig"
                      id="url_utm"
                      name="url_utm"
                      v-model="url_utm"
                      type="text"
                      :placeholder="$t('seller.seller.links.new.text_2738')"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>


    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="secondary_outline"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        {{ $t("seller.seller.links.new.text_2740") }}
      </BaseButton>
      <BaseButton
        variant="info3"
        class="mr-md-4"
        :disabled="loading"
        @click="copyLink"
      >
        {{ $t("seller.seller.links.new.text_2741") }}
      </BaseButton>
      <BaseButton
        variant="secondary"
        v-if="
          (all_edit === null && !$store.getters.setPanel) ||
          ($store.getters.setPanel && !link_read)
        "
        :disabled="loading"
        @click="onSubmit"
      >
        {{ $t("seller.seller.links.new.text_2742") }}
      </BaseButton>
      <BaseButton
        variant="secondary"
        v-if="all_edit !== null && !$store.getters.setPanel"
        :disabled="loading"
        @click="onUpdate"
      >
        {{ $t("seller.seller.links.new.text_2743") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import LinkService from "@/services/resources/LinkService";
const service = LinkService.build();
import CouponService from "@/services/resources/CouponService";
const serviceCoupon = CouponService.build();
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  components: {
    Multiselect,
  },
  props: {
    link_read: {
      type: Boolean,
      default: false,
    },
    product_installments: {
      type: Boolean,
    },
    checkouts: {
      type: Array,
    },
    _cupom: {},
    has_orderbump: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
    },
    productsS: {
      type: Array,
    },
    productsA: {
      type: Array,
    },
    product_id: {
      type: Number,
      default: -1,
    },
    product_type: {
      type: String,
    },
    product_name: {
      type: String,
      default: "",
    },
    all_edit: {
      type: Object,
    },
    "is-physical": {
      type: Boolean,
    },
  },
  mounted() {
    this.sales_page = process.env.VUE_SALES_PAGE + "/";

    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "links-new") {
        this.resetItens();
      }
    });

  },

  data() {
    return {
      url: "",
      name: "",
      source: "",
      medium: "",
      submit: false,
      loading: false,
      cupom: false,
      checkout: false,
      order_b: false,
      productsSellerOptions: [],
      productsAffiliateOptions: [],
      product_main: null,
      product_order_b: null,
      checkout_select: null,
      cupom_select: null,
      offer_select: null,
      offer_bump_select: null,
      cupons: [],
      offers: [],
      offers_bump: [],
      sales_page: null,
      url_utm: "",
    };
  },
  computed: {
    productId() {
      if(this.product_id > 0 && !this.product_main){
        this.product_main = this.productsSellerOptions.find((p) => p.id === this.product_id);
      }
      if(this.product_id < 0 && !this.product_main){
        return null
      }
      return this.product_id > 0 && !this.product_main
        ? this.product_id
        : this.product_main
        ? this.product_main.id
        : null;
    },
  },
  watch: {
    url() {
      this.updateUrl();
    },
    source() {
      this.updateUrl();
    },
    medium() {
      this.updateUrl();
    },
    name() {
      this.updateUrl();
    },
  },
  methods: {
    updateUrl() {
      if (!this.order_b && !this.checkout && !this.cupom) {
        const encodedSource = encodeURIComponent(this.source);
        const encodedMedium = encodeURIComponent(this.medium);
        const encodedCampaign = encodeURIComponent(this.name);
        this.url_utm = `${this.url}?source=${encodedSource}&medium=${encodedMedium}&campaign=${encodedCampaign}`;
      }
    },
    async openModal() {
      this.productsSellerOptions = [...this.productsS];
      this.productsAffiliateOptions = [...this.productsA];
      if (this.all_edit !== null) {
        this.order_b = !!this.all_edit.bump_id;

        this.product_order_b = this.all_edit.product_order_b[0];
        this.name = this.all_edit.name;
        this.offer_select = this.all_edit.offer_select
          ? this.all_edit.offer_select
          : null;
        this.offer_bump_select = this.all_edit.offer_bump_select
          ? this.all_edit.offer_bump_select
          : null;

        if (this.offer_select) {
          this.getOffers(null, false);
        }
        if (this.offer_bump_select) {
          this.getOffers({ id: this.offer_bump_select.product_id }, false);
        }
        await this.getCupom()
        if(this.all_edit.url.indexOf("cupom=") > -1){
          this.cupom = true;

          const urlCupom = this.all_edit.url.split("cupom=")[1];
          const indexOfNext = urlCupom.indexOf("&");
          const cupom = indexOfNext > -1 ? urlCupom.substring(0, indexOfNext) : urlCupom;

          this.cupom_select = this.cupons.find((c) => c.name === cupom);
        }
        if(this.all_edit.url.indexOf('ch_id') > -1){
          this.checkout = true;
          const urlCheckout = this.all_edit.url.split("ch_id=")[1];
          const indexOfNext = urlCheckout.indexOf("&");
          const checkout = indexOfNext > -1 ? urlCheckout.substring(0, indexOfNext) : urlCheckout;
          this.checkout_select = this.checkouts.find((c) => c.id === parseInt(checkout));
        }
        
      }
      if (this._cupom && this.$parent.cupom) {
        this.cupom = true;
        this.changeCheckBox(true, "cupom");
        this.cupom_select = this._cupom;

        this.$bvToast.toast(this.$t("seller.seller.links.new.text_295"), {
          title: this.$t("seller.seller.links.new.text_2744"),
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }

      if (this.has_orderbump) {
        this.order_b = true;
      }
      if(!this.all_edit){
        this.cupons = [];
        this.offers = [];
        this.offers_bump = [];
      }
    },
    getOffers(bump, reset = true) {
      if(!this.productId) return false;
      this.loading = true;
      let data = {
        id: this.productId + "/offers",
      };

      if (bump) {
        this.offers_bump = [];
        this.offer_bump_select = reset ? null : this.offer_bump_select;
        data.id = bump.id + "/offers";
      } else {
        this.offers = [];
        reset && this.resetSelects();
      }

      serviceProduct
        .read(data)
        .then((response) => {
          if (this.all_edit === null) {
            this.offer_bump_select = null;
          }
          response.forEach((el) => {
            const data = {
              id: el.id,
              name: el.name,
              max_boleto_installments: el.max_boleto_installments,
              hash: el.hash,
              status: el.status,
              method: el.method,
              isBump: !!bump
            }
            if(!bump) {
              this.offers.push(data);
            } else {
              this.offers_bump.push(data);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCupom() {
      this.loading = true;

      let data = {
        id: `product/${this.product_id}`,
      };

      this.cupons = [];

      return serviceCoupon
        .read(data)
        .then((response) => {
          response.forEach((el) => {
            this.cupons.push({
              id: el.id,
              name: el.name,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeCheckBox(event, type) {
      // console.log(this.productsS);
      if (event) {
        if (type == "cupom") {
          this.getCupom();
        }
        // this.offer_select = null;
        this.getOffers();
      } else {
        if (type == "cupom") {
          this.cupom_select = null;
        } else if (type == "checkout") {
          this.checkout_select = null;
        } else if (type == "orderbump") {
          this.product_order_b = null;
          this.offer_bump_select = null;
        }
      }
    },
    filterBy(data) {
      return data.filter((data) => {
        if (
          this.productId == data.id  || data.status !== 'APPROVED'
        ) {
          return (data.$isDisabled = true);
        } else {
          delete data.$isDisabled;
          return data;
        }
      });
    },
    filterByOffer(data) {
      return data.filter((data) => {
        if (
          data.status !== "APPROVED" || (this.offer_select && data.id == this.offer_select.id)
        ) {
          return (data.$isDisabled = true);
        } else {
          if (data.isBump) {
            if(this.offers.length && this.offer_select){
              const mainOfferMethod = this.offer_select.method.split(',');
              const currentOfferMethod = data.method.split(',');
              const compatibleMethods = mainOfferMethod.some((method) => currentOfferMethod.includes(method));
              if(!compatibleMethods) {
                data.invalidMethods = true
                // data.$isDisabled = true
                return data;
              } else {
                data.invalidMethods = false
              }
               
            }
          }
          delete data.$isDisabled;
          return data;
        }
      });
    },
    customLabel({ title }) {
      return `${title}`;
    },
    copyLink() {
      if (this.$refs.linkconfig) {
        navigator.clipboard.writeText(this.$refs.linkconfig.value);
        this.$bvToast.toast(this.$t("seller.seller.links.new.text_296"), {
          title: this.$t("seller.seller.links.new.text_2745"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    resetItens() {
      this.productsSellerOptions.map((el) => {
        if(el.status !== 'APPROVED'){
          el.$isDisabled = true;
        } else {
          el.$isDisabled = false;
          delete el.$isDisabled;
        }
        return el
      })
      this.productsAffiliateOptions.map((el) => {
        if(el.status !== 'APPROVED'){
          el.$isDisabled = true;
        } else {
          el.$isDisabled = false;
          delete el.$isDisabled;
        }
        return el
      })
      this.order_b = false;
      this.cupom = false;
      this.checkout = false;
      this.name = null;
      this.product_main = null;
      this.product_id = null;
      this.offer_select = null;
      this.offer_bump_select = null;
      this.all_edit = null;
      this.product_order_b = [];
      this.checkout_select = null;
      this.cupom_select = null;
      this.$parent.cupom = null;
      this.$emit("close");
    },
    resetSelects() {
      this.offer_select = null;
      this.offer_bump_select = null;
      this.product_order_b = [];
      this.checkout_select = null;
      this.cupom_select = null;
      this.$parent.cupom = null;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    formatId(orderb, checkout, cupom, offer_select, offer_bump_select) {
      let link = "";
      if (Array.isArray(orderb) && orderb.length > 0) {
        orderb = orderb[0];
      }
      if (JSON.stringify(orderb) === "[]") {
        orderb = null;
      }

      if (!orderb && checkout === null && cupom === null) {
        // No parameters to add
      } else {
        if (orderb) {
          link += `/?b_id=${orderb.id}`;
        }

        if (checkout) {
          link += link ? `&ch_id=${checkout.id}` : `/?ch_id=${checkout.id}`;
        }

        if (cupom) {
          link += link ? `&cupom=${cupom.name}` : `/?cupom=${cupom.name}`;
        }
      }

      if (orderb && checkout && cupom) {
        if (this.checkout && this.order_b && this.cupom) {
          link = `/?b_id=${orderb.id}&ch_id=${checkout.id}&cupom=${cupom.name}`;
        } else if (this.checkout && !this.order_b && !this.cupom) {
          link = `/?ch_id=${checkout.id}`;
        } else if (!this.checkout && this.order_b && !this.cupom) {
          link = `/?b_id=${orderb.id}`;
        } else if (!this.checkout && !this.order_b && this.cupom) {
          link = `/?cupom=${cupom.name}`;
        }
      }

      if (offer_select) {
        link = `/offer/${offer_select.hash}${link}`;
      }

      if (offer_bump_select) {
        link += link ? `&b_offer=${offer_bump_select.hash}` : `/?b_offer=${offer_bump_select.hash}`;
      }

      if (this.type) {
        const queryAfiliado = `a_id=${this.userID()}`;
        link += link ? `&${queryAfiliado}` : `/?${queryAfiliado}`;
      }

      return this.sales_page + this.productId + link;
    },
    onUpdate() {
      this.submit = true;
      this.loading = true;

      let data = {
        id: this.all_edit.id,
        url: this.$refs.linkconfig.value,
        name: this.name,
        source: this.source,
        medium: this.medium,
        product_id: this.productId,
      };


      data.is_bump = this.order_b;
       

      if (this.offer_select) {
        data.offer_select = this.offer_select;
      }

      if (this.offer_bump_select) {
        data.offer_bump_select = this.offer_bump_select;
      }

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {

            if(this.offer_bump_select && this.offer_bump_select.invalidMethods){
              this.$bvToast.toast(this.$t("seller.seller.links.new.bumpPaymentMethodInvalid"), {
                title: this.$t("seller.seller.links.new.bumpPaymentMethodInvalidTitle"),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }

            service
              .update(data)
              .then(() => {
                this.$emit("close");
                this.$bvModal.hide("links-new");
                this.$bvToast.toast(this.$t("seller.seller.links.new.text_297"), {
                    title: this.$t("seller.seller.links.new.text_2745"),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                });
              })
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.submit = true;
      this.loading = true;

      const linkConfigRef = this.$refs.linkconfig;

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            if(this.offer_bump_select && this.offer_bump_select.invalidMethods){
              this.$bvToast.toast(this.$t("seller.seller.links.new.bumpPaymentMethodInvalid"), {
                title: this.$t("seller.seller.links.new.bumpPaymentMethodInvalidTitle"),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }
            let urlMode = "";

            if(this.order_b === false && this.checkout === false && this.cupom === false){ 
              if (!this.url.startsWith('https://')) {
                this.$bvToast.toast(
                  this.$t("seller.seller.links.new.text_300"),
                  {
                    title: this.$t("seller.seller.links.new.text_301"),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                return;
              }
            }

            if (
              this.checkout === true ||
              this.order_b === true ||
              this.cupom === true
            ) {
              urlMode = linkConfigRef.value;
            } else {
              let newQuery = this.source ? `${this.source}=${this.medium}` : "";

              urlMode = this.url;
              let has_query = new URL(urlMode);

              if (this.source) {
                urlMode += has_query.search && has_query.search.length > 0 ? `&${newQuery}` : `?${newQuery}`;
              }
            }
            if (this.order_b === true && this.product_order_b === null) {
              this.$bvToast.toast(
                "Necessário selecionar um produto order bump",
                {
                  title: "Links",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              return;
            }

            if (this.order_b === true && this.product_order_b === null) {
              this.$bvToast.toast(
                this.$t("seller.seller.links.new.text_2746"),
                {
                  title: this.$t("seller.seller.links.new.text_2745"),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              return;
            }

            if (this.checkout === true && this.checkout_select === null) {
              this.$bvToast.toast(
                this.$t("seller.seller.links.new.text_2747"),
                {
                  title: this.$t("seller.seller.links.new.text_2745"),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              return;
            }

            if (this.order_b || this.checkout || this.cupom) {
              this.url_utm = null;
            }

            let data = {
              url: this.url_utm === null ? urlMode : this.url_utm,
              name: this.name,
              source: this.source,
              medium: this.medium,
              product_id: this.productId,
            };

            if (this.order_b) {
              data.is_bump = true;
            }

            if (this.offer_select) {
              data.offer_select = this.offer_select;
            }

            if (this.offer_bump_select) {
              data.offer_bump_select = this.offer_bump_select;
            }
            
            service
              .create(data)
              .then(() => {
                this.$emit("close");
                this.url = "";
                this.name = "";
                this.source = "";
                this.medium = "";
                this.$bvModal.hide("links-new");
                this.$bvToast.toast(
                  this.$t("seller.seller.links.new.text_298"),
                  {
                    title: this.$t("seller.seller.links.new.text_2745"),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );

                if (this._cupom) {
                  this.$parent.cupom = null;
                }
              })
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
          } else {
            this.submit = false;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>

.is-invalid input, .is-invalid select, .is-invalid textarea {
  border-color: #dc3545 !important;
  
}
input.is-invalid {
  border-color: #dc3545 !important;
}
.multiselect__option--disabled span {
  color: #ccc;

}
.produto-nome {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #0d0d0f;
}

.info-mark {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.label-title {
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #2a63ab;
}

.title-checkbox {
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: #333333;
  font-weight: normal;
}
.checks {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.checks .title-checkbox {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .checks {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
</style>